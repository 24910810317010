import { Link } from "react-router-dom";
import { MultiSectionBatch } from ".";

type Props = MultiSectionBatch;

export const Tile = ({ title, operation, Icon, actions }: Props) => {
  return (
    <div className="box-border flex h-[30rem] w-[30rem] shrink-0 flex-col items-center justify-start rounded-xl border border-solid border-gray-300 bg-[#fff] px-[5rem] py-10">
      <p className="whitespace-nowrap text-xl font-bold">{title}</p>
      <div className="mb-11 mt-12 flex h-[4.8rem] w-[4.8rem] shrink-0 items-center justify-center">
        <Icon className="h-11 w-11 text-gray-600" />
      </div>
      <div className="flex flex-col gap-5">
        {actions.map((action) => {
          return (
            <Link
              className="buiButtonBase buiButtonVariantOutline buiButtonSizeSm buiButtonBlock buiButtonRound"
              to={`/multi_section_batches/${operation}`}
              key={operation}
            >
              {action.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
