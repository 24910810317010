import Table from "../../../../components/atoms/Table";
import styles from "./styles.scss";
import SkillInterface from "../../../../interfaces/SkillInterface";
import { Link } from "react-router-dom";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { SkillRow } from "./SkillRow";

type Props = {
  skills: SkillInterface[];
  section: SectionInterface;
  loading: boolean;
};

const SectionSkills = (props: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.buttonRow}>
        <Link
          to={`/sections/${props.section.id}/settings/skill_masters`}
          className={`buiButtonBase buiButtonVariantFilled`}
        >
          新規作成
        </Link>
      </div>
      <div className={styles.skills}>
        {props.skills.length === 0 ? (
          <p className={styles.noSkillsText}>
            アシスタントスキルの登録がありません。右上の「新規作成」からアシスタントスキルを登録できます。
          </p>
        ) : (
          <Table
            headers={[
              { text: "アシスタントスキル名", centering: true },
              { text: "説明", centering: true },
              { text: "ステータス", centering: true },
              { text: "", centering: true },
            ]}
            rows={props.skills.map((skill) => (
              <SkillRow
                key={skill.id}
                sectionId={props.section.id}
                skill={skill}
              />
            ))}
            headColumnClassName={styles.headColumn}
          />
        )}
      </div>
    </div>
  );
};

export default SectionSkills;
