import * as React from "react";
import { RenderParameters } from "pdfjs-dist/types/src/display/api";
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import * as pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
import { Buffer } from "buffer";
import { useFlashMessage } from "../../../hooks/useFlashMessage";
import { PDFDocumentProxy } from "pdfjs-dist/webpack";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface UseAttachmentViewerProps {
  files: File[] | null;
  maxPageNo: number;
}

export interface ParsedFilesProps {
  cachedImageUrl: string;
  filename: string;
  file: File;
  numOfPages: number;
}

interface PdfProps extends ParsedFilesProps {
  pdfDoc: PDFDocumentProxy;
  pdfPage: number;
}

export const useAttachmentViewer = (props: UseAttachmentViewerProps) => {
  const [parsedFiles, setParsedFiles] = React.useState<
    ParsedFilesProps[] | PdfProps[]
  >([]);
  const [numOfImages, setNumOfImages] = React.useState(0);
  const [pageNo, setPageNo] = React.useState(0);
  const [isParsing, setIsParsing] = React.useState(false);
  const availablePrev = pageNo >= 1;
  const availableNext =
    pageNo + 1 < parsedFiles.length && pageNo + 1 < props.maxPageNo;

  // cachedImageUrlに画像URLをセットする
  // NOTE: 画像URLの生成が画面描画処理のボトルネックになるため、画像が必要になった時に初めて画像URLを生成します
  React.useEffect(() => {
    const parsedFile = parsedFiles[pageNo];
    if (parsedFile?.cachedImageUrl || !parsedFile?.file) return;

    if (parsedFile.file.type === "application/pdf") {
      const pdfDoc = (parsedFile as PdfProps).pdfDoc;
      const pdfPage = (parsedFile as PdfProps).pdfPage;
      getImageDataUrl(pdfDoc, pdfPage).then((url) => {
        setParsedFiles((prev) => {
          const newParsedFiles = [...prev];
          newParsedFiles[pageNo].cachedImageUrl = url;
          return newParsedFiles;
        });
      });
    } else {
      setParsedFiles((prev) => {
        const newParsedFiles = [...prev];
        newParsedFiles[pageNo].cachedImageUrl = window.URL.createObjectURL(
          parsedFile.file,
        );
        return newParsedFiles;
      });
    }
  }, [parsedFiles[pageNo]?.cachedImageUrl]);

  React.useEffect(() => {
    return () => {
      // NOTE: メモリリークを防ぐため、画像URLを解放します
      //       https://developer.mozilla.org/ja/docs/Web/API/URL/createObjectURL_static#%E3%83%A1%E3%83%A2%E3%83%AA%E3%83%BC%E7%AE%A1%E7%90%86
      parsedFiles.forEach((parsedFile) => {
        URL.revokeObjectURL(parsedFile.cachedImageUrl);
      });
    };
  }, []);
  const { showErrorMessage } = useFlashMessage();

  React.useEffect(() => {
    async function convertToImage() {
      setIsParsing(true);
      const newParsedFiles: ParsedFilesProps[] = [];
      let parsedNumOfImages = 0;
      if (props.files) {
        for (const file of props.files) {
          const cachedParsedFiles = parsedFiles.filter(
            (parsedFile) => parsedFile.file === file,
          );
          if (cachedParsedFiles.length === 0) {
            switch (file.type) {
              case "application/pdf": {
                const files = await getAllParsedFiles(
                  file,
                  props.maxPageNo,
                ).catch(() => {
                  showErrorMessage("ファイルの読み込みに失敗しました。");
                  return [] as ParsedFilesProps[];
                });
                newParsedFiles.push(...files);
                parsedNumOfImages += files[0].numOfPages;
                break;
              }
              case "image/jpg":
              case "image/jpeg":
              case "image/png": {
                newParsedFiles.push({
                  cachedImageUrl: "",
                  filename: file.name,
                  file: file,
                  numOfPages: 1,
                });
                parsedNumOfImages++;
              }
            }
          } else {
            newParsedFiles.push(...cachedParsedFiles);
            parsedNumOfImages += cachedParsedFiles[0].numOfPages;
          }
        }
      }
      setParsedFiles(newParsedFiles);
      setNumOfImages(parsedNumOfImages);
      setIsParsing(false);
    }
    convertToImage();
  }, [props.files?.map((file) => file.name + file.lastModified).join(",")]);

  React.useEffect(() => {
    setPageNo(0);
  }, [parsedFiles.length]);

  const isCurrentFile = (file: File) => {
    if (parsedFiles.length === 0 || !parsedFiles[pageNo]) {
      return false;
    }
    return parsedFiles[pageNo].file === file;
  };

  return {
    parsedFiles,
    numOfImages,
    pageNo,
    setPageNo,
    availablePrev,
    availableNext,
    isCurrentFile,
    isParsing,
  };
};

const getAllParsedFiles = async (pdfFile: File, maxPageNo: number) => {
  const pdfDoc = await pdfjsLib
    .getDocument({
      url: window.URL.createObjectURL(pdfFile),
      cMapUrl: "/cmaps/",
      cMapPacked: true,
    })
    .promise.catch(() => {
      throw new Error("pdfjsLib.getDocument failed");
    });
  const parsedFiles: ParsedFilesProps[] = [];
  for (let i = 1; i <= Math.min(pdfDoc._pdfInfo.numPages, maxPageNo); i++) {
    parsedFiles.push({
      cachedImageUrl: "",
      filename: pdfFile.name,
      file: pdfFile,
      numOfPages: pdfDoc._pdfInfo.numPages,
      pdfDoc,
      pdfPage: i,
    } as PdfProps);
  }
  return parsedFiles;
};

const getImageDataUrl = async (pdf: PDFDocumentProxy, pageNo: number) => {
  const page = await pdf.getPage(pageNo);
  const viewport = page.getViewport({ scale: 1 });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = viewport.width;
  canvas.height = viewport.height;

  if (context) {
    const renderContext: RenderParameters = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext).promise;
  }
  const blob = toBlob(canvas.toDataURL());
  if (!blob) {
    return "";
  }
  return URL.createObjectURL(blob);
};

const toBlob = (base64: string) => {
  const bin = Buffer.from(base64.replace(/^.*,/, ""), "base64").toString(
    "binary",
  );
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  try {
    const blob = new Blob([buffer.buffer], {
      type: "image/png",
    });
    return blob;
  } catch (e) {
    return false;
  }
};
