import { useBoronMutation } from "../../../hooks/http/useBoronMutation";
import { boronClient } from "../../../api";

export const usePutEmailConfirmationToken = () =>
  useBoronMutation(async ({ token }: { token: string }) =>
    boronClient.PUT("/api/v1/settings/email/{token}", {
      params: {
        path: {
          token,
        },
      },
    }),
  );
