import * as React from "react";
import styles from "./styles.scss";
import Icon from "../../../../components/atoms/Icon";
import { Modal } from "../../../../components/atoms/Modal/index";
import { Button } from "@studyplus/boron-ui";
import { Form, Field, Formik } from "formik";
import validationSchema from "./validationSchema";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import StudentTag from "../../../../domains/StudentTag";
import { TableColumn } from "../../../../components/atoms/Table";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { UnprocessableEntityError } from "../../../../errors";
import { useUpdateTag } from "./useUpdateTag";
import { useDeleteTag } from "./useDeleteTag";

type Props = {
  sectionId: string;
  tag: StudentTag;
  submitting: boolean;
  onSubmitCallback: (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => void;
};

interface Values {
  name: string;
}

const SectionTagItem = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [apiErrors, setApiErrors] = React.useState<ApiErrorInterface[]>([]);

  const { showSuccessMessage, showErrorMessage } = useFlashMessage();

  const handleOpenUpdateModal = () => {
    setIsOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsOpen(false);
  };

  const { mutate: updateTag } = useUpdateTag({
    sectionId: props.sectionId,
  });

  const { mutate: deleteTag } = useDeleteTag({
    sectionId: props.sectionId,
  });

  const handleSubmit = (values: Values) => {
    updateTag(
      { tagId: props.tag.id, name: values.name },
      {
        onSuccess: ({ tag }) => {
          showSuccessMessage("タグを更新しました");
          props.onSubmitCallback("update", {
            id: tag.id,
            name: tag.name,
            type: "tag_ids",
            enabled: true,
          });
          setApiErrors([]);
          setIsOpen(false);
        },
        onError: (error) => {
          if (error instanceof UnprocessableEntityError) {
            setApiErrors(error.originalErrors);
          }
          showErrorMessage("タグを更新できませんでした");
        },
      },
    );
  };

  const handleDelete = () => {
    if (
      confirm(
        "コンテンツ配信機能の配信タグとして設定されている場合、生徒へ送信した全てのURLが無効になります。削除してよろしいですか？",
      )
    ) {
      deleteTag(
        { tagId: props.tag.id },
        {
          onSuccess: () => {
            showSuccessMessage("タグを削除しました");
            props.onSubmitCallback("delete", props.tag);
          },
          onError: (error) => {
            if (error instanceof UnprocessableEntityError) {
              showErrorMessage(
                "アシスタントの集計対象として使用中のためタグを削除できません",
              );
            }
            showErrorMessage("タグを削除できませんでした");
          },
        },
      );
    }
  };

  return (
    <tr className={styles.row}>
      <TableColumn className={styles.nameCell}>{props.tag.name}</TableColumn>
      <TableColumn className={styles.actionCell}>
        <div className={styles.actionList}>
          <button className={styles.link} onClick={handleOpenUpdateModal}>
            <Icon name="icon-edit" />
            編集
          </button>
          {isOpen && (
            <Modal
              isOpen={isOpen}
              onRequestClose={handleCloseUpdateModal}
              className={styles.modal}
            >
              <Modal.Header onClose={handleCloseUpdateModal}>
                <h2 className={styles.modalheader}>タグを編集</h2>
              </Modal.Header>
              <Modal.Body>
                <Formik
                  initialValues={{
                    name: props.tag.name,
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                  initialErrors={{}}
                >
                  {(formikProps) => (
                    <Form>
                      <label
                        htmlFor={`sectionTag-${props.tag.id}--name`}
                        className={styles.label}
                      >
                        タグ名
                      </label>
                      <NameError
                        touched={formikProps.touched.name}
                        error={formikProps.errors.name}
                      />
                      <ApiErrors tagId={props.tag.id} apiErrors={apiErrors} />
                      <Field
                        id={`sectionTag-${props.tag.id}--name`}
                        name="name"
                        className={styles.input}
                      />
                      <div className={styles.form_row}>
                        <Button
                          disabled={props.submitting || !formikProps.isValid}
                          type="submit"
                          className={styles.btn}
                        >
                          保存
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
            </Modal>
          )}
          <button className={styles.link} onClick={handleDelete}>
            <Icon name="icon-trash" />
            削除
          </button>
        </div>
      </TableColumn>
    </tr>
  );
};

const NameError = ({
  touched,
  error,
}: {
  touched?: boolean;
  error?: string;
}) => {
  if (error && touched) {
    return <p className={styles.input__error}>{error}</p>;
  } else {
    return null;
  }
};

const ApiErrors = ({
  tagId,
  apiErrors,
}: {
  tagId: string;
  apiErrors: ApiErrorInterface[];
}) => {
  if (!apiErrors || apiErrors.length === 0) {
    return null;
  }
  return (
    <>
      {apiErrors.map((err: ApiErrorInterface, idx: number) => (
        <p className={styles.input__error} key={`sectionTag-${tagId}-${idx}`}>
          {err.message}
        </p>
      ))}
    </>
  );
};

export default SectionTagItem;
