import * as React from "react";
import styles from "./styles.scss";
import {
  convertStudentFormValueToParams,
  StudentWithStayInterface,
} from "../../../interfaces/StudentInterface";
import { Link } from "react-router-dom";
import SectionInterface from "../../../interfaces/SectionInterface";
import { OperatorPrivilegeInfoInterface } from "../../../interfaces/OperatorInterface";
import StudentEditModal from "../StudentEditModal";
import { HandleThunkActionCreator } from "react-redux";
import { updateStudent } from "../../../actions/common/student";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import { StudentFormValueInterface } from "../../../interfaces/StudentInterface";
import { toggleNavigation } from "../../../actions/toggleNavigation";
import { connect } from "react-redux";
import { StudentAvatar } from "./StudentAvatar";
import { BillingPlanLabel } from "../../features/BillingPlanLabel";

interface Props {
  privileges: OperatorPrivilegeInfoInterface;
  student: StudentWithStayInterface;
  section: SectionInterface;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
  updateStudent: HandleThunkActionCreator<typeof updateStudent>;
}

interface DispatchProps {
  toggleNavigation: typeof toggleNavigation;
}

const StudentNaviProfile = (props: Props & DispatchProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleSubmit = (values: StudentFormValueInterface) => {
    props.updateStudent(
      props.student.id,
      convertStudentFormValueToParams(values),
      () => setIsOpen(false),
    );
  };

  const handleClickMessage = () => {
    props.toggleNavigation();
  };

  return (
    <div className={styles.root}>
      <StudentAvatar stayStatus={props.student.stayStatus} />
      <div className={styles.fullName}>
        <BillingPlanLabel billingPlan={props.student.billingPlan} />
        {props.privileges.role === "admin" ||
        props.privileges.role === "unlimited_member" ? (
          <button
            onClick={() => setIsOpen(true)}
            className={styles.studentEditBtn}
            aria-describedby="student-stay-status"
          >
            {props.student.fullName}
          </button>
        ) : (
          props.student.fullName
        )}
      </div>
      <div className={styles.job}>{`(${props.student.jobHuman})`}</div>
      <div>
        <Link
          to={`/students/${props.student.id}/messages`}
          onClick={handleClickMessage}
          className={`buiButtonBase buiButtonVariantFilled ${styles.messageLink}`}
        >
          メッセージ
        </Link>
      </div>
      <StudentEditModal
        isOpen={isOpen}
        student={props.student}
        submitting={props.submitting}
        onSubmit={handleSubmit}
        onRequestClose={() => setIsOpen(false)}
        apiErrors={props.apiErrors}
      >
        <h2>生徒を編集</h2>
      </StudentEditModal>
    </div>
  );
};

export default connect(null, { toggleNavigation })(StudentNaviProfile);
