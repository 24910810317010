import styles from "./styles.scss";
import SectionInterface from "../../../../interfaces/SectionInterface";
import { StudentFormValueInterface } from "../../../../interfaces/StudentInterface";
import StudentForm from "../../../../components/molecules/StudentForm";
import { ApiErrorInterface } from "../../../../interfaces/ApiErrorResponseInterface";
import { useNavigate } from "react-router-dom";
import { useFlashMessage } from "../../../../hooks/useFlashMessage";
import { UnprocessableEntityError } from "../../../../errors";
import { useState } from "react";
import { usePostStudent } from "./usePostStudent";

type Props = {
  section: SectionInterface;
};

const StudentNewForm = (props: Props) => {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage } = useFlashMessage();
  const [apiErrors, setApiErrors] = useState<ApiErrorInterface[]>([]);

  const { mutate, isPending } = usePostStudent({ sectionId: props.section.id });

  const handleSubmit = (values: StudentFormValueInterface) => {
    mutate(values, {
      onSuccess: () => {
        showSuccessMessage("生徒を登録しました");
        setApiErrors([]);
        navigate(`/sections/${props.section.id}/settings/students`);
      },
      onError: (error) => {
        if (error instanceof UnprocessableEntityError) {
          setApiErrors(error.originalErrors);
        } else {
          setApiErrors([]);
        }
        showErrorMessage("生徒を登録できませんでした");
      },
    });
  };

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>生徒の登録</h2>
      <div className={styles.form}>
        <StudentForm
          onSubmit={handleSubmit}
          apiErrors={apiErrors}
          submitting={isPending}
        />
      </div>
    </div>
  );
};

export default StudentNewForm;
