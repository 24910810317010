import { useState } from "react";
import { Button, IconImportStudent } from "@studyplus/boron-ui";
import enhanceHomePage from "../../hocs/enhanceHomePage";
import OperatorInterface from "../../interfaces/OperatorInterface";
import ContentBox from "../../components/atoms/ContentBox";
import { MultiSectionBatchesDescription } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDescription";
import { MultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/MultiSectionBatchesAdminSectionsSelectBox";
import { useMultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/useMultiSectionBatchesAdminSectionsSelectBox";
import { useMultiSectionBatchesDownload } from "../../hooks/http/useMultiSectionBatchesDownload";
import classNames from "classnames";

type Props = {
  currentOperator: OperatorInterface;
};

const Index = ({ currentOperator }: Props) => {
  const { adminSections, isLoading, hasReachedSectionsLimit, toggleCheckbox } =
    useMultiSectionBatchesAdminSectionsSelectBox({
      operatorId: currentOperator.id,
    });
  const { isPending, mutate } = useMultiSectionBatchesDownload({
    multiSectionBatchType: "download_students",
  });

  const [hasSectionsError, setHasSectionsError] = useState(false);
  const createDownloadFile = () => {
    const isSelectedSections = adminSections.some((section) => section.checked);
    setHasSectionsError(!isSelectedSections);

    if (!isSelectedSections) return;

    mutate({ adminSections });
  };

  const canCreate = !isLoading && adminSections.length > 0 && !isPending;

  return (
    <div className="flex w-[90%] flex-col">
      <h1 className="text-bold my-10 text-3xl text-black">校舎横断操作</h1>
      <ContentBox className="w-[80%] p-10">
        <div>
          <div className="flex items-center gap-6">
            <IconImportStudent className="h-[3rem] w-[3rem] text-gray-600" />
            <h2 className="text-xl">生徒一覧のダウンロード</h2>
          </div>
          <MultiSectionBatchesDescription
            helpUrl="https://fs-help.studyplus.co.jp/ja/articles/9856602"
            isOnlyProPlanStudent={false}
          />
          <div className="flex flex-col gap-10">
            <MultiSectionBatchesAdminSectionsSelectBox
              hasSectionsError={hasSectionsError}
              isLoading={isLoading}
              adminSections={adminSections}
              hasReachedSectionsLimit={hasReachedSectionsLimit}
              toggleCheckbox={toggleCheckbox}
            />
            <div className="flex justify-end">
              <Button
                className={classNames({
                  "hover:border-blue-300 hover:bg-blue-300": canCreate,
                })}
                disabled={!canCreate}
                onClick={createDownloadFile}
              >
                作成
              </Button>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export const MultiSectionBatchesDownloadStudentsPage = enhanceHomePage(Index, {
  title: "校舎横断操作",
});
