import styles from "./styles.scss";
import SectionInterface from "../../../../interfaces/SectionInterface";
import SectionTagItem from "../SectionTagItem";
import StudentTag from "../../../../domains/StudentTag";
import Table, { TableColumn } from "../../../../components/atoms/Table";

interface Props {
  section: SectionInterface;
  tags: StudentTag[];
  submitting: boolean;
  onSubmitCallback: (
    type: "create" | "update" | "delete",
    tag: StudentTag,
  ) => void;
}

const SectionTagList = (props: Props) => {
  return (
    <Table
      className={styles.root}
      headers={[
        { text: "タグ名", centering: false, className: styles.header },
        { text: "", centering: false, className: styles.actionHeader },
      ]}
      rows={renderItems(props)}
    />
  );
};

const renderItems = (props: Props) => {
  const { section, tags, submitting, onSubmitCallback } = props;

  if (tags.length == 0) {
    return [
      <tr key="no-tags">
        <TableColumn colSpan={2}>
          <p className={styles.notfound}>タグが登録されていません</p>
        </TableColumn>
      </tr>,
    ];
  }

  return tags.map((tag: StudentTag) => {
    return (
      <SectionTagItem
        key={`sectionTag-${tag.id}`}
        sectionId={section.id}
        tag={tag}
        submitting={submitting}
        onSubmitCallback={onSubmitCallback}
      />
    );
  });
};

export default SectionTagList;
