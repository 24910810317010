import { addDays, isAfter, isBefore, subDays } from "date-fns";
import { useState } from "react";
import { Matcher } from "react-day-picker";

export const useMultiSectionBatchesDateRangePicker = () => {
  const [dateRange, setDateRange] = useState<{
    startDate?: Date;
    endDate?: Date;
  }>({ startDate: undefined, endDate: undefined });

  // 期間（endDate - StartDate)は31日以内である必要がある
  const isOutsideRange = (date: Date) => {
    const allowedDateRangeDays = 31;

    if (!dateRange?.startDate) return false;

    const isBeforeStartDate = isBefore(date, dateRange.startDate);
    const isAfterEndDate = isAfter(
      date,
      addDays(dateRange.startDate, allowedDateRangeDays - 1),
    );

    return isBeforeStartDate || isAfterEndDate;
  };

  // 昨日以前のみ選択可能
  const isAfterYesterday = (date: Date) => {
    const yesterday = subDays(new Date(), 1);
    return isAfter(date, yesterday);
  };

  const disabled: Matcher | Matcher[] = [isOutsideRange, isAfterYesterday];

  const resetDateRange = () => {
    if (
      dateRange?.startDate !== undefined &&
      dateRange?.endDate !== undefined
    ) {
      setDateRange({ startDate: undefined, endDate: undefined });
    }
  };

  return {
    dateRange,
    setDateRange,
    disabled,
    resetDateRange,
  };
};
