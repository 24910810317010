import {
  IconDoorOpen,
  IconImportSeiseki,
  IconImportStudent,
  IconKarte,
  IconSectionUsageRate,
  IconTextbook,
} from "@studyplus/boron-ui";
import enhanceHomePage from "../../hocs/enhanceHomePage";
import { Tile } from "./Tile";

export type MultiSectionBatch = {
  title: string;
  operation: string;
  Icon: React.ComponentType<{ className: string }>;
  actions: { name: string }[];
};

const multiSectionBatches: MultiSectionBatch[] = [
  {
    title: "学習記録のダウンロード",
    operation: "download_study_records",
    Icon: IconTextbook,
    actions: [
      {
        name: "ダウンロード",
      },
    ],
  },
  {
    title: "入退室記録のダウンロード",
    operation: "download_stays",
    Icon: IconDoorOpen,
    actions: [
      {
        name: "ダウンロード",
      },
    ],
  },
  {
    title: "面談記録のダウンロード",
    operation: "download_kartes",
    Icon: IconKarte,
    actions: [
      {
        name: "ダウンロード",
      },
    ],
  },
  {
    title: "生徒一覧のダウンロード",
    operation: "download_students",
    Icon: IconImportStudent,
    actions: [
      {
        name: "ダウンロード",
      },
    ],
  },
  {
    title: "成績記録のダウンロード",
    operation: "download_examination_results",
    Icon: IconImportSeiseki,
    actions: [{ name: "ダウンロード" }],
  },
  {
    title: "校舎別活用率のダウンロード",
    operation: "download_section_usage_rates",
    Icon: IconSectionUsageRate,
    actions: [{ name: "ダウンロード" }],
  },
];

const Index = () => {
  return (
    <div className="w-[95%]">
      <div className="">
        <h1 className="text-bold my-10 text-center text-3xl">校舎横断操作</h1>
        <div className="bg-gray-100 p-10">
          <div className="flex flex-wrap gap-x-10 gap-y-20">
            {multiSectionBatches.map((operation) => (
              <Tile key={operation.operation} {...operation} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MultiSectionBatchesPage = enhanceHomePage(Index, {
  title: "校舎横断操作",
});
