import SectionInterface from "./SectionInterface";
import StudentTag from "../domains/StudentTag";
import { ApiErrorInterface } from "./ApiErrorResponseInterface";
import { SchoolType } from "../consts/SchoolType";
import { GuardianEmailStatus } from "../domains/GuardianEmail";
import { BillingPlan } from "../domains/BillingPlan";

export type StatusType = "preinviting" | "inviting" | "active" | "inactive";

export type LineConnectionStatusType = "unissued" | "waiting" | "connected";
export type StayStatusTypes = "entered" | "exited";
export default interface StudentInterface {
  id: string;
  fullName: string;
  firstName: string;
  firstNameKana: string;
  lastName: string;
  lastNameKana: string;
  code: string | null;
  status: StatusType;
  statusHuman: "連携中" | "招待前" | "招待中" | "停止中";
  schoolType: SchoolType;
  schoolTypeHuman:
    | "未設定"
    | "中学校"
    | "高校"
    | "高等専門学校"
    | "大学"
    | "大学院"
    | "短期大学"
    | "専門学校"
    | "高卒生"
    | "社会人"
    | "小学校";
  job: string;
  grade: number | null;
  jobHuman: string;
  canSendComment: boolean;
  canSendMessage: boolean;
  canCreateKarteAttachment: boolean;
  canSendGuardianMessage: boolean;
  isGuardianEmailConfirmed: boolean;
  tags: Pick<StudentTag, "id" | "name">[];
  smartTags: (Pick<StudentTag, "id" | "name"> & { type: "smart_tag" })[];
  section: SectionInterface;
  lineCode: string | null;
  lineConnectionStatus: LineConnectionStatusType;
  lineConnectionStatusHuman: "未発行" | "未連携" | "連携中";
  passcode: string | null;
  apiErrors?: ApiErrorInterface[];
  isDeletable: boolean; // 生徒削除が行えるかどうか
  guardianEmails?: GuardianEmailStatus[];
  billingPlan: BillingPlan;
}

export type RecipientStudent = StudentInterface & {
  receivedAt: string | null;
};

export interface StudentWithStayInterface extends StudentInterface {
  stayStatus: StayStatusTypes;
}

export interface StudentStateInterface {
  data: StudentWithStayInterface | null;
  submitting: boolean;
  apiErrors: ApiErrorInterface[];
}

// 生徒の登録/更新フォームの値
export interface StudentFormValueInterface {
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  school_type: SchoolType | null;
  grade: number | null;
  code: string;
}

// 生徒の登録/更新時にAPIに渡すパラメータ
export interface StudentParamsInterface {
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  school_type: SchoolType;
  grade?: number;
  code?: string;
}

export const convertStudentFormValueToParams = (
  values: StudentFormValueInterface,
): StudentParamsInterface => ({
  ...values,
  school_type: values.school_type || "unknown",
  grade: values.grade || undefined,
  code: values.code,
});
