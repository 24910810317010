import { HTTP_ERROR_MESSAGE } from "./reducers/index";
import EmergencyInterface from "./interfaces/EmergencyInterface";

// TODO: Usage のコメントを書く
export abstract class FSHTTPError extends Error {
  abstract code: string;
}
export class UnauthorizedError extends FSHTTPError {
  public readonly code = "401";
  constructor() {
    super("Unauthorized Error");
    // ランタイム側でも正しく instanceof が行われるように
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}
export class NotFoundError extends FSHTTPError {
  public readonly code = "404";
  constructor() {
    super("NotFound Error");
    // ランタイム側でも正しく instanceof が行われるように
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class ForbiddenError extends FSHTTPError {
  public readonly code = "403";
  constructor() {
    super("Forbidden Error");
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class SteakEmergencyError extends FSHTTPError {
  public readonly code = "503";
  public emergency: EmergencyInterface = {
    title: "",
    body: "",
  };
  constructor(emergency: EmergencyInterface) {
    super("Steak Emergency Error");
    Object.setPrototypeOf(this, SteakEmergencyError.prototype);
    this.emergency = emergency;
  }
}
export class MaintenanceError extends FSHTTPError {
  public readonly code = "503";
  public maintenance = "";
  constructor(maintenance: string) {
    super("Maintenance Error");
    Object.setPrototypeOf(this, MaintenanceError.prototype);
    this.maintenance = maintenance;
  }
}

export type UnprocessableEntityErrorItem = {
  resource?: string;
  field?: string;
  message?: string;
};
// 422 のエラー
export class UnprocessableEntityError extends FSHTTPError {
  public readonly code = "422";
  public messages: ReadonlyArray<string> = [];
  public originalErrors: UnprocessableEntityErrorItem[];
  public errorTable: Record<string, string>;
  constructor(errors: UnprocessableEntityErrorItem[]) {
    super("Unprocessable Entity");
    Object.setPrototypeOf(this, UnprocessableEntityError.prototype);
    this.originalErrors = errors;
    this.errorTable = this.originalErrors.reduce((p, c) => {
      if (c.field === undefined || c.message === undefined) {
        return p;
      }

      return {
        ...p,
        [c.field]: c.message,
      };
    }, {});
  }
  public getErrorMessages() {
    return this.originalErrors.map((err) => err.message);
  }
}

export type HTTPErrors =
  | UnauthorizedError
  | NotFoundError
  | ForbiddenError
  | SteakEmergencyError
  | MaintenanceError
  | UnprocessableEntityError
  | Error;

export const matchHttpError = (
  error: HTTPErrors,
  callbacks: {
    caseUnauthorized?: () => void;
    caseNotFound?: () => void;
    caseForbiden?: () => void;
    caseSteakEmergencyError?: (emergency: EmergencyInterface) => void;
    caseMaintenanceError?: (maintenance: string) => void;
    caseOthers?: () => void;
  },
) => {
  if (error instanceof UnauthorizedError) {
    return callbacks.caseUnauthorized?.();
  }
  if (error instanceof NotFoundError) {
    return callbacks.caseNotFound?.();
  }
  if (error instanceof ForbiddenError) {
    return callbacks.caseForbiden?.();
  }
  if (error instanceof SteakEmergencyError) {
    return callbacks.caseSteakEmergencyError?.(error.emergency);
  }
  if (error instanceof MaintenanceError) {
    return callbacks.caseMaintenanceError?.(error.maintenance);
  }
  callbacks.caseOthers?.();
};

export const createError = async (res: Response) => {
  if (res.status === 401) {
    return new UnauthorizedError();
  }
  if (res.status === 404) {
    return new NotFoundError();
  }
  if (res.status === 403) {
    return new ForbiddenError();
  }
  if (res.status === 422) {
    const json = await res.json();
    return new UnprocessableEntityError(
      json.errors as UnprocessableEntityErrorItem[],
    );
  }
  if (res.status === 503) {
    try {
      const json = await res.json();
      if (json.errors[0].emergency) {
        return new SteakEmergencyError(json.errors[0].emergency);
      }
      if (json.errors[0].maintenance) {
        return new MaintenanceError(json.errors[0].maintenance);
      }
      return new Error(HTTP_ERROR_MESSAGE);
    } catch (e) {
      return new Error(HTTP_ERROR_MESSAGE);
    }
  }

  return new Error(HTTP_ERROR_MESSAGE);
};

export const isUnprocessableEntityError = (
  error: HTTPErrors,
): error is UnprocessableEntityError =>
  error instanceof UnprocessableEntityError;
