import Checkbox from "../../components/atoms/Checkbox";
import { Modal } from "../../components/atoms/Modal";
import Button from "../../components/atoms/Button/index";
import styles from "./ConsentAgreement.scss";
import { useConsentAgreement } from "./useConsentAgreement";

export const ConsentAgreement = () => {
  const consentAgreement = useConsentAgreement();
  return (
    <Modal isOpen={consentAgreement.isOpen}>
      <Modal.NoCloseHeader>
        <h2>重要なお知らせ</h2>
      </Modal.NoCloseHeader>
      <Modal.Body>
        <div className="mt-7">
          <div className="text-center">
            いつもStudyplus for Schoolをご利用いただきありがとうございます。
            <br />
            <span className="font-bold">
              利用規約・プライバシーポリシーに同意
            </span>
            の上、サービスをご利用ください。
          </div>
        </div>
        <form onSubmit={consentAgreement.handleSubmit}>
          <div className="flex justify-center">
            <div>
              <div className="mt-10 flex items-center">
                <Checkbox
                  aria-label="利用規約・プライバシーポリシーに同意する"
                  id="consentAgreement"
                  className="mr-1"
                  {...consentAgreement.checkboxProps}
                />
                <span>
                  <a
                    href="https://for-school.studyplus.co.jp/terms"
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold text-blue-400 underline"
                  >
                    利用規約
                  </a>
                  ・
                  <a
                    href="https://info.studyplus.co.jp/privacy_policy"
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold text-blue-400 underline"
                  >
                    プライバシーポリシー
                  </a>
                  に同意する
                  <span className="ml-1 mt-2 text-md font-bold text-red-400">
                    *必須
                  </span>
                </span>
              </div>
              {consentAgreement.errors && (
                <p className="absolute mt-1 text-md text-red-400">
                  {consentAgreement.errors}
                </p>
              )}
            </div>
          </div>
          <div className="mb-7 mt-11 flex flex-col items-center">
            <Button
              type="submit"
              className={styles.submitButton}
              isLoading={consentAgreement.isPending}
            >
              引き続き利用する
            </Button>
            <button
              className="mt-9 font-bold text-blue-400"
              onClick={consentAgreement.signOut}
              type="button"
            >
              同意しない（ログアウト）
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
