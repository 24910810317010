import styles from "./styles.scss";
import { Field, ErrorMessage } from "formik";
import Checkbox from "../../atoms/Checkbox";

type Props = {
  id: string;
};

export const TermAndPolicyField = (props: Props) => {
  return (
    <>
      <Field
        name="isTermsAccepted"
        component={Checkbox}
        labelMargin="narrow"
        id={props.id}
      >
        <a
          href="https://for-school.studyplus.co.jp/terms"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.termLink}
        >
          利用規約
        </a>
        、
        <a
          href="https://info.studyplus.co.jp/privacy_policy"
          target="_blank"
          rel="noreferrer"
          className={styles.termLink}
        >
          プライバシーポリシー
        </a>
        に同意する
      </Field>
      <ErrorMessage
        component="p"
        name="isTermsAccepted"
        className={styles.errorMessage}
      />
    </>
  );
};
