import ApiClient from "../../../api";
import { useFlashMessage } from "../../../hooks/useFlashMessage";

type Props = {
  batchId: number;
};

export const useDownloadFile = ({ batchId }: Props) => {
  const { showErrorMessage } = useFlashMessage();

  const downloadFile = async () => {
    try {
      const url = `/api/v1/sections/multi_section_batch_histories/${batchId}/download`;
      const response = await ApiClient.get(url);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");

      downloadLink.style.display = "none";
      downloadLink.href = blobUrl;
      const filename = extractFileName(
        response.headers.get("content-disposition"),
      );
      if (filename) {
        downloadLink.download = decodeURI(filename);
      }

      document.body.appendChild(downloadLink);
      downloadLink.click();

      // 不要なリソースを削除
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
    } catch (_) {
      showErrorMessage("ダウンロードできませんでした");
    }
  };

  return {
    downloadFile,
  };
};

const extractFileName = (contentDisposition: string | null): string | null => {
  if (!contentDisposition) {
    return null;
  }
  const match = contentDisposition.match(/filename\*=UTF-8''(.+)/);
  return match ? match[1] : null;
};
