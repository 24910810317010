import * as React from "react";
import styles from "./styles.scss";
import { Button } from "@studyplus/boron-ui";
import SelectField from "../../atoms/DeprecatedSelectField/index";
import StudentInterface from "../../../interfaces/StudentInterface";
import { useStudyScheduleForm } from "./useStudyScheduleForm";
import {
  BookshelfEntry,
  BookshelfStatusType,
} from "../../../domains/BookshelfEntry";
import { MAX_VALUES } from "../../../domains/StudySchedule";
import { useMutateScheduleFromStudyScheduleForm } from "../../../containers/pages/StudentsCalendar/useScheduleApi";
import ErrorText from "../../atoms/ErrorText/index";
import { Schedule } from "../../../domains/Schedule";
import { DateRange } from "../../general/DynamicDateRangePicker";
import { DateRangePickerField } from "../../atoms/DateRangePickerField/index";
import Input from "../../atoms/Input/index";
import Textarea from "../../atoms/Textarea/index";
import Label from "../../atoms/Label";

export interface Props {
  student: StudentInterface;
  loadingBookshelfEntries: boolean;
  bookshelfEntries: readonly BookshelfEntry[];
  onMutateSuccess: (message: string) => void;
  onMutateError: (message: string) => void;
}

export const StudyScheduleForm = (props: Props) => {
  const learningMaterialOptions = React.useMemo(
    () => makeOptions(props.bookshelfEntries),
    [props.bookshelfEntries],
  );

  const { mutate, data, error, isLoading } =
    useMutateScheduleFromStudyScheduleForm({
      studentId: props.student.id,
    });

  const handleSubmit = React.useCallback((schedule: Schedule) => {
    mutate({ schedule });
  }, []);

  const form = useStudyScheduleForm({
    student: props.student,
    bookshelfEntries: props.bookshelfEntries,
    onSubmit: handleSubmit,
  });

  const handleChangeDates = React.useCallback((dates: DateRange) => {
    form.setFieldValue("initialDate", dates.startDate);
    form.setFieldValue("endDate", dates.endDate);
  }, []);

  const selectedMaterialUnit = React.useMemo(() => {
    const selectedMaterial = props.bookshelfEntries.find(
      (bookshelfEntry) =>
        bookshelfEntry.learningMaterial.code === form.values.steakMaterialCode,
    );

    return selectedMaterial?.learningMaterial.unit || "ページ";
  }, [form.values.steakMaterialCode]);

  React.useEffect(() => {
    if (data) {
      props.onMutateSuccess("学習計画を作成しました。");
      form.resetForm();
    }
  }, [data]);

  // 2022年10月27日現在、formik v2.2.7以上で handleChangeDates内でsetFieldTouchedすると、
  // form.values.endDateに日付が入っていてもform.errors.endDateにエラーメッセージが入ってしまう不具合が発生。
  // 2.2.7のアップデートで挙動が変わったのは間違いなさそうだが、原因がわからない。
  // ワークアラウンドとしてuseEffect内でsetFieldTouchedすることで回避できた
  // (useEffectで実行することで少し呼び出しのタイミングがずれる)
  React.useEffect(() => {
    if (form.values.initialDate || form.values.endDate) {
      form.setFieldTouched("initialDate");
      form.setFieldTouched("endDate");
    }
  }, [form.values.initialDate, form.values.endDate]);

  React.useEffect(() => {
    if (error) {
      props.onMutateError("学習計画を作成できませんでした。");
    }
  }, [error]);

  return (
    <form className={styles.root} onSubmit={form.handleSubmit}>
      <div className={styles.row}>
        {/* 教材 */}
        <Label isMute>教材を選ぶ</Label>
        <SelectField
          options={learningMaterialOptions}
          value={form.values.steakMaterialCode}
          onChange={(option: { label: string; value: string }) => {
            form.setFieldValue("steakMaterialCode", option.value);
            form.setFieldTouched("steakMateralCode");
          }}
          placeholder={"教材を選択"}
          noOptionsMessage={
            props.loadingBookshelfEntries
              ? "読み込み中です..."
              : "教材が見つかりませんでした"
          }
          className={styles.learningMaterial}
        />
      </div>

      {form.touched.steakMaterialCode ? (
        <ErrorText>{form.errors.steakMaterialCode}</ErrorText>
      ) : null}

      <div className={`${styles.row} ${styles.rowBetween}`}>
        {/* 学習時間 */}
        <div className={styles.seconds}>
          <Label isMute>学習時間</Label>
          <div className={styles.secondsField}>
            <Input
              name="hours"
              type="number"
              min={0}
              max={MAX_VALUES.hours}
              aria-label="学習時間(時間)"
              value={form.values.hours}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              onClick={focusOnClick}
              onMouseUp={blurOnMouseup}
              hasError={form.touched.hours && !!form.errors.hours}
            />
            <span className={styles.secondsField__unit}>時間</span>
            <Input
              name="minutes"
              type="number"
              min={0}
              max={MAX_VALUES.minutes}
              aria-label="学習時間(分)"
              value={form.values.minutes}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              onClick={focusOnClick}
              onMouseUp={blurOnMouseup}
              hasError={form.touched.minutes && !!form.errors.minutes}
            />
            <span className={styles.secondsField__unit}>分</span>
          </div>
        </div>

        {/* 学習量 */}
        <div className={styles.amount}>
          <Label isMute>学習量</Label>
          <div className={styles.amountField}>
            <Input
              name="amount"
              type="number"
              min={0}
              max={MAX_VALUES.amount}
              value={form.values.amount}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              onClick={focusOnClick}
              onMouseUp={blurOnMouseup}
              hasError={!!form.errors.amount && form.touched.amount}
            />
            <span className={styles.amountField__unit}>
              {selectedMaterialUnit}
            </span>
          </div>
        </div>
      </div>

      {/* 学習量、学習時間いずれかが未入力なときのエラー */}
      {(form.touched.hours && form.errors.hours) ||
      (form.touched.minutes && form.errors.minutes) ||
      (form.touched.amount && form.errors.amount) ? (
        <ErrorText>
          {form.errors.hours || form.errors.minutes || form.errors.amount}
        </ErrorText>
      ) : null}

      <div className={styles.row}>
        {/* 計画期間 */}
        <div className={styles.date}>
          <Label isMute>計画期間</Label>
          <DateRangePickerField
            dateRange={{
              startDate: form.values.initialDate,
              endDate: form.values.endDate,
            }}
            onChangeDateRange={handleChangeDates}
            placeholder="計画期間を選択"
          />
          {form.touched.initialDate && form.touched.endDate ? (
            <>
              <ErrorText>{form.errors.initialDate}</ErrorText>
              <ErrorText>{form.errors.endDate}</ErrorText>
            </>
          ) : null}
        </div>

        {/* メモ */}
        <div className={styles.comment}>
          <Label isMute>{"メモ　※メモは生徒の学習計画にも表示されます"}</Label>
          <Textarea
            name="comment"
            maxLength={MAX_VALUES.comment}
            rows={1}
            aria-label="コメント"
            hasError={form.touched.comment && !!form.errors.comment}
            onChange={form.handleChange}
            value={form.values.comment}
          />
          {form.touched.comment && !!form.errors.comment ? (
            <ErrorText>{form.errors.comment}</ErrorText>
          ) : null}
        </div>
      </div>

      {/* APIからのエラー */}
      <ErrorText>{error}</ErrorText>

      {/* 登録ボタン */}
      <div className={`${styles.row} ${styles.rowAlignEnd}`}>
        <Button
          type="submit"
          disabled={!form.isValid || isLoading}
          className={styles.button}
          size="md"
          isLoading={isLoading}
        >
          登録
        </Button>
      </div>
    </form>
  );
};

const makeOptionsByStatus = (
  bookshelfEntries: readonly BookshelfEntry[],
  status: BookshelfStatusType,
) => {
  return bookshelfEntries
    .filter((bookshelfEntry) => bookshelfEntry.status === status)
    .map((bookshelfEntry) => ({
      label: bookshelfEntry.learningMaterial.name,
      value: bookshelfEntry.learningMaterial.code,
    }));
};

const makeOptions = (bookshelfEntries: readonly BookshelfEntry[]) => {
  if (bookshelfEntries.length === 0) {
    return [];
  }

  return [
    {
      label: "勉強中",
      options: makeOptionsByStatus(bookshelfEntries, "in_progress"),
    },
    {
      label: "スタンバイ",
      options: makeOptionsByStatus(bookshelfEntries, "open"),
    },
    {
      label: "完了",
      options: makeOptionsByStatus(bookshelfEntries, "closed"),
    },
  ];
};

const blurOnMouseup = (e: React.MouseEvent<HTMLInputElement>) => {
  e.currentTarget.blur();
};
const focusOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
  e.currentTarget.focus();
};
