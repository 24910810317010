import { useState } from "react";
import { Button, IconDoorOpen } from "@studyplus/boron-ui";
import enhanceHomePage from "../../hocs/enhanceHomePage";
import OperatorInterface from "../../interfaces/OperatorInterface";
import ContentBox from "../../components/atoms/ContentBox";
import { MultiSectionBatchesDescription } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDescription";
import { MultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/MultiSectionBatchesAdminSectionsSelectBox";
import { MultiSectionBatchesDownloadDateRange } from "../../components/features/MultiSectionBatches/MultiSectionBatchesDownloadDateRange";
import { useMultiSectionBatchesAdminSectionsSelectBox } from "../../components/features/MultiSectionBatches/useMultiSectionBatchesAdminSectionsSelectBox";
import { useMultiSectionBatchesDownloadDateRange } from "../../components/features/MultiSectionBatches/useMultiSectionBatchesDownloadDateRange";
import { useMultiSectionBatchesDownload } from "../../hooks/http/useMultiSectionBatchesDownload";
import classNames from "classnames";

type Props = {
  currentOperator: OperatorInterface;
};

const Index = ({ currentOperator }: Props) => {
  const { adminSections, isLoading, hasReachedSectionsLimit, toggleCheckbox } =
    useMultiSectionBatchesAdminSectionsSelectBox({
      operatorId: currentOperator.id,
    });
  const {
    daysDuration,
    startDate,
    endDate,
    calendarFromDate,
    calendarToDate,
    setStartDate,
  } = useMultiSectionBatchesDownloadDateRange();
  const { isPending, mutate } = useMultiSectionBatchesDownload({
    multiSectionBatchType: "download_stays",
  });

  const [hasSectionsError, setHasSectionsError] = useState(false);
  const createDownloadFile = () => {
    const isSelectedSections = adminSections.some((section) => section.checked);
    setHasSectionsError(!isSelectedSections);

    if (!isSelectedSections) return;

    mutate({
      adminSections,
      startDate,
      endDate,
    });
  };

  const canCreate = !isLoading && adminSections.length > 0 && !isPending;

  return (
    <div className="flex w-[90%] flex-col">
      <h1 className="text-bold my-10 text-3xl text-black">校舎横断操作</h1>
      <ContentBox className="w-[80%] p-10">
        <div>
          <div className="flex items-center gap-6">
            <IconDoorOpen className="h-[3rem] w-[3rem] text-gray-600" />
            <h2 className="text-xl">入退室記録のダウンロード</h2>
          </div>
          <MultiSectionBatchesDescription
            helpUrl="https://fs-help.studyplus.co.jp/ja/articles/9817593"
            isOnlyProPlanStudent
          />
          <div className="flex flex-col gap-10">
            <MultiSectionBatchesAdminSectionsSelectBox
              hasSectionsError={hasSectionsError}
              isLoading={isLoading}
              adminSections={adminSections}
              hasReachedSectionsLimit={hasReachedSectionsLimit}
              toggleCheckbox={toggleCheckbox}
            />
            <MultiSectionBatchesDownloadDateRange
              daysDuration={daysDuration}
              startDate={startDate}
              setStartDate={setStartDate}
              calendarFromDate={calendarFromDate}
              calendarToDate={calendarToDate}
            />
            <div className="flex justify-end">
              <Button
                className={classNames({
                  "hover:border-blue-300 hover:bg-blue-300": canCreate,
                })}
                disabled={!canCreate}
                onClick={createDownloadFile}
              >
                作成
              </Button>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export const MultiSectionBatchesDownloadStaysPage = enhanceHomePage(Index, {
  title: "校舎横断操作",
});
