import * as React from "react";
import StudentInterface, {
  StudentFormValueInterface,
} from "../../../interfaces/StudentInterface";
import { HandleThunkActionCreator } from "react-redux";
import { Modal } from "../../atoms/Modal/index";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import StudentForm from "../StudentForm";

interface Props extends ReactModal.Props {
  isOpen: boolean;
  student: StudentInterface;
  submitting: boolean;
  onSubmit: HandleThunkActionCreator<
    (values: StudentFormValueInterface) => void
  >;
  onRequestClose: () => void;
  apiErrors: ApiErrorInterface[];
}

const StudentEditModal: React.FunctionComponent<Props> = (
  props: React.PropsWithChildren<Props>,
) => {
  return (
    <div onClick={stopPropagation}>
      <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose}>
        <Modal.Header onClose={props.onRequestClose}>
          {props.children}
        </Modal.Header>
        <Modal.Body>
          <StudentForm
            onSubmit={props.onSubmit}
            submitting={props.submitting}
            student={props.student}
            apiErrors={props.apiErrors}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export default StudentEditModal;
