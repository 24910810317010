import * as React from "react";
import styles from "./styles.scss";
import StudentInterface from "../../../interfaces/StudentInterface";
import { StudentFormValueInterface } from "../../../interfaces/StudentInterface";
import { HandleThunkActionCreator } from "react-redux";
import { ApiErrorInterface } from "../../../interfaces/ApiErrorResponseInterface";
import {
  FormikProps,
  withFormik,
  FormikBag,
  Form,
  Field,
  ErrorMessage,
} from "formik";
import * as yup from "yup";
import ErrorText from "../../atoms/ErrorText";
import Label from "../../atoms/Label";
import Input from "../../atoms/Input";
import SelectField from "../../atoms/DeprecatedSelectField";
import { Button } from "@studyplus/boron-ui";
import { SchoolTypeNames } from "../../../consts/SchoolType";

interface ComponentProps {
  student?: StudentInterface;
  onSubmit: HandleThunkActionCreator<(values: Values) => void>;
  apiErrors: ApiErrorInterface[];
  submitting: boolean;
}

type Values = StudentFormValueInterface;

type Props = ComponentProps & FormikProps<Values>;

const StudentForm: React.FunctionComponent<Props> = (props: Props) => {
  const schoolTypeOptions = Object.entries(SchoolTypeNames).map(
    ([value, label]) => ({ value, label }),
  );
  const gradeOptions = Array.from(Array(9), (_, i) => ({
    value: i + 1,
    label: i + 1,
  }));
  const studentId: string = props.student ? props.student.id : "new";

  const hasError = (name: string): boolean => {
    const e: ApiErrorInterface | undefined = props.apiErrors.find(
      (apiError: ApiErrorInterface) => apiError.field === name,
    );
    if (e) return true;

    const errors = (props.errors as any)[name];
    const touched = (props.touched as any)[name];
    return errors && touched;
  };

  const renderError = (name: string) => {
    const error: ApiErrorInterface | undefined = props.apiErrors.find(
      (apiError: ApiErrorInterface) => apiError.field === name,
    );
    if (error) {
      return (
        <ErrorText className={styles.errorMessage}>{error.message}</ErrorText>
      );
    }

    return (
      <ErrorMessage
        name={name}
        component={ErrorText}
        className={styles.errorMessage}
      />
    );
  };

  return (
    <Form aria-label="生徒フォーム">
      <div className={styles.input_group}>
        <Label htmlFor={`student_${studentId}__last_name`} required={true}>
          お名前
        </Label>
        <div className={styles.row}>
          <div className={styles.input}>
            <Field
              name="last_name"
              component={Input}
              aria-label="姓"
              hasError={hasError("last_name")}
            />
            {renderError("last_name")}
          </div>
          <div className={styles.input}>
            <Field
              name="first_name"
              component={Input}
              aria-label="名"
              hasError={hasError("first_name")}
            />
            {renderError("first_name")}
          </div>
        </div>
      </div>
      <div className={styles.input_group}>
        <Label htmlFor={`student_${studentId}__last_name_kana`} required={true}>
          フリガナ
        </Label>
        <div className={styles.row}>
          <div className={styles.input}>
            <Field
              name="last_name_kana"
              component={Input}
              aria-label="セイ"
              hasError={hasError("last_name_kana")}
            />
            {renderError("last_name_kana")}
          </div>
          <div className={styles.input}>
            <Field
              name="first_name_kana"
              component={Input}
              aria-label="メイ"
              hasError={hasError("first_name_kana")}
            />
            {renderError("first_name_kana")}
          </div>
        </div>
      </div>
      <div className={styles.input_group}>
        <Label htmlFor={`student_${studentId}__school_type`} required={true}>
          学校種別
        </Label>
        <Field
          inputId={`student_${studentId}__school_type`}
          name="school_type"
          component={SelectField}
          options={schoolTypeOptions}
          value={props.values.school_type}
          form={props}
          isClearable={true}
          placeholder="学校種別を選択"
          hasError={hasError("school_type")}
        />
        {renderError("school_type")}
      </div>
      <div className={styles.input_group}>
        <Label htmlFor={`student_${studentId}__grade`}>学年</Label>
        <Field
          id={`student_${studentId}__grade`}
          name="grade"
          component={SelectField}
          options={gradeOptions}
          isClearable={true}
          placeholder="学年を選択"
          hasError={hasError("grade")}
        />
        {renderError("grade")}
      </div>
      <div className={styles.input_group}>
        <Label htmlFor={`student_${studentId}__code`}>生徒ID（任意）</Label>
        <Field
          id={`student_${studentId}__code`}
          name="code"
          component={Input}
          hasError={hasError("code")}
        />
        {renderError("code")}
      </div>
      <div className={styles.buttonGroup}>
        <Button type="submit" disabled={props.submitting || !props.isValid}>
          {props.student ? "更新" : "登録"}
        </Button>
      </div>
    </Form>
  );
};

// ミドルネームなどの生徒で区切り文字を入れてくる場合があるので、
// 「・」を許容している
const KatakanaRegex = /^[ァ-ヴー・]*$/;
export default withFormik({
  mapPropsToValues: (props: ComponentProps): Values => {
    if (props.student) {
      const { student } = props;
      return {
        last_name: student.lastName,
        first_name: student.firstName,
        last_name_kana: student.lastNameKana,
        first_name_kana: student.firstNameKana,
        school_type: student.schoolType,
        grade: student.grade,
        code: student.code || "",
      };
    } else {
      return {
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        school_type: null,
        grade: null,
        code: "",
      };
    }
  },
  handleSubmit: (
    values: Values,
    formikBag: FormikBag<ComponentProps, Values>,
  ) => {
    formikBag.props.onSubmit(values);
  },
  validationSchema: yup.object().shape({
    last_name: yup
      .string()
      .trim()
      .required("姓を入力してください")
      .max(25, "姓は25文字以下でご入力ください"),
    first_name: yup
      .string()
      .trim()
      .required("名を入力してください")
      .max(25, "名は25文字以下でご入力ください"),
    last_name_kana: yup
      .string()
      .trim()
      .required("セイを入力してください")
      .max(25, "セイは25文字以下でご入力ください")
      .matches(KatakanaRegex, "全角カタカナでご入力ください"),
    first_name_kana: yup
      .string()
      .trim()
      .required("メイを入力してください")
      .max(25, "メイは25文字以下でご入力ください")
      .matches(KatakanaRegex, "全角カタカナでご入力ください"),
    school_type: yup
      .string()
      .nullable()
      .trim()
      .required("学校種別を選択してください"),
    code: yup
      .string()
      .nullable(true)
      .max(64, "生徒IDは64文字以下でご入力ください"),
  }),
  enableReinitialize: true,
})(StudentForm);
