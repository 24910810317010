import { useState } from "react";
import { addDays, startOfWeek, subDays } from "date-fns";

export const useMultiSectionBatchesDownloadDateRange = () => {
  const today = new Date();

  // デフォルトは8日以上前で最新の月曜日
  const initialStartDate = (() => {
    const eightDaysAgo = subDays(today, 8);
    const monday = startOfWeek(eightDaysAgo, { weekStartsOn: 1 });

    return monday;
  })();
  const [startDate, setStartDate] = useState<Date>(initialStartDate);

  const daysDuration = 7; // NOTE: 現状は1週間固定
  const endDate = addDays(startDate, daysDuration - 1);

  // 選択可能な日付
  // 2024年4月1日以降
  const calendarFromDate = new Date(2024, 3, 1); // NOTE: JSのDateのMonthは0から始まるため、4月の場合は3を指定する
  // 1週間前以前
  const calendarToDate = subDays(today, daysDuration);

  return {
    daysDuration,
    startDate,
    endDate,
    calendarFromDate,
    calendarToDate,
    setStartDate,
  };
};
