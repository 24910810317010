import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { createError, HTTPErrors } from "../../../errors";
import { boronClient } from "../../../api";
import { getNextPageParam } from "../../../helpers/ReactQueryHelper";
import { paths } from "../../../lib/api/v1";
import SkillInterface from "../../../interfaces/SkillInterface";

type Response =
  paths["/api/v1/sections/{section_id}/skills"]["get"]["responses"]["200"]["content"]["application/json"]["skills"];

export const useFetchSkills = ({ sectionId }: { sectionId: string }) => {
  const query = useInfiniteQuery<Response, HTTPErrors>({
    queryKey: ["skills", { sectionId }],
    queryFn: async ({ pageParam }) => {
      const { response, data } = await boronClient.GET(
        "/api/v1/sections/{section_id}/skills",
        {
          params: {
            path: { section_id: sectionId },
            query: { page: pageParam as number },
          },
        },
      );
      if (response.ok && data) {
        return data.skills;
      }
      throw await createError(response);
    },
    initialPageParam: 1,
    getNextPageParam,
    refetchOnWindowFocus: false, // reduxだったときにはrefetchしていなかったので合わせる
  });

  const skills = query.data?.pages.flatMap((page) => page.data) ?? [];

  return {
    skills,
    ...query,
  };
};

export const useUpdateSkillsCache = ({ sectionId }: { sectionId: string }) => {
  const queryClient = useQueryClient();

  const updateSkillsCache = (skill: Partial<SkillInterface>) => {
    queryClient.setQueriesData<InfiniteData<Response>>(
      { queryKey: ["skills", { sectionId }] },
      (old) => {
        if (!old) {
          return old;
        }
        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            data: page.data.map((s) =>
              s.id === skill.id ? { ...s, ...skill } : s,
            ),
          })),
        };
      },
    );
  };

  return {
    updateSkillsCache,
  };
};

export const useDeleteSkillsCache = ({ sectionId }: { sectionId: string }) => {
  const queryClient = useQueryClient();

  const deleteSkillsCache = (skillId: string) => {
    queryClient.setQueriesData<InfiniteData<Response>>(
      { queryKey: ["skills", { sectionId }] },
      (old) => {
        if (!old) {
          return old;
        }
        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            data: page.data.filter((s) => s.id !== skillId),
          })),
        };
      },
    );
  };

  return {
    deleteSkillsCache,
  };
};
