import classNames from "classnames";
import { DateRangePickerField } from "../../atoms/DateRangePickerField";
import { differenceInDays } from "date-fns";
import { Matcher } from "react-day-picker";
import { DateRange } from "../../general/DynamicDateRangePicker";

type Props = {
  dateRange: DateRange;
  disabled: Matcher | Matcher[];
  hasDateRangePickerError: boolean;
  setDateRange: (dateRange: DateRange) => void;
  resetDateRange: () => void;
};

export const MultiSectionBatchesDownloadDateRangePicker = ({
  dateRange,
  disabled,
  hasDateRangePickerError,
  setDateRange,
  resetDateRange,
}: Props) => {
  return (
    <div
      className={classNames(
        "flex flex-col gap-8 rounded-lg bg-[#fafafa] px-10 py-[22px]",
        {
          "border border-solid border-red-400": hasDateRangePickerError,
        },
      )}
    >
      <div>
        <p>2. 出力したい期間を選択してください</p>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-8">
          <span className="w-[30rem]" onClick={resetDateRange}>
            <DateRangePickerField
              dateRange={dateRange}
              onChangeDateRange={(dates) => {
                setDateRange(dates);
              }}
              placeholder="出力期間を選択"
              disabled={disabled}
              className="bg-white"
              fromDate={new Date(2024, 3, 1)} // NOTE: 2024/4/1 以降の日付を選択可能にする
            />
          </span>
          {dateRange.startDate !== undefined &&
            dateRange.endDate !== undefined && (
              <p className="font-bold">
                の{differenceInDays(dateRange.endDate, dateRange.startDate) + 1}
                日間
              </p>
            )}
        </div>
      </div>
      {hasDateRangePickerError && (
        <p className="m-4 text-red-400">期間を選択してください</p>
      )}
    </div>
  );
};
