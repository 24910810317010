import * as React from "react";
import { useDispatch } from "react-redux";
import type { AnyAction, Dispatch } from "redux";
import EmergencyInterface from "../interfaces/EmergencyInterface";
import { maintenanceError, unauthorizedError } from "../actions/common/errors";
import {
  notFoundError,
  forbiddenError,
  steakError,
} from "../actions/common/errors";

export const useErrorDispatcher = () => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();

  const sendUnauthorized = React.useCallback(() => {
    dispatch(unauthorizedError());
  }, []);

  const sendNotFound = React.useCallback(() => {
    dispatch(notFoundError());
  }, []);

  const sendForbidden = React.useCallback(() => {
    dispatch(forbiddenError());
  }, []);

  const sendSteakEmergencyError = React.useCallback(
    (error: EmergencyInterface) => {
      dispatch(steakError(error));
    },
    [],
  );

  const sendMaintenanceError = React.useCallback((error: string) => {
    dispatch(maintenanceError(error));
  }, []);

  return {
    sendUnauthorized,
    sendNotFound,
    sendForbidden,
    sendSteakEmergencyError,
    sendMaintenanceError,
  };
};
