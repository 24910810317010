import { format } from "date-fns";
import { DateRange } from "../../general/DynamicDateRangePicker";

type Props = {
  dateRange: DateRange;
  placeholder: string;
};

export const Dates = ({
  dateRange: { startDate, endDate },
  placeholder,
}: Props) => {
  if (!startDate && !endDate) {
    return <div>{placeholder}</div>;
  }

  return (
    <div className="flex items-center gap-4">
      <span>{startDate ? format(startDate, "yyyy/M/d") : ""}</span>
      <span>〜</span>
      <span>{endDate ? format(endDate, "yyyy/M/d") : ""}</span>
    </div>
  );
};
