import { useIsMutating } from "@tanstack/react-query";
import { buildKey as buildCreatingKey } from "./SectionTagForm/usePostTag";
import { buildKey as buildUpdatingKey } from "./SectionTagItem/useUpdateTag";
import { buildKey as buildDeletingKey } from "./SectionTagItem/useDeleteTag";

export const useIsSubmitting = ({ sectionId }: { sectionId: string }) => {
  const creatingCount = useIsMutating({
    mutationKey: buildCreatingKey(sectionId),
  });
  const updatingCount = useIsMutating({
    mutationKey: buildUpdatingKey(sectionId),
  });

  const deletingCount = useIsMutating({
    mutationKey: buildDeletingKey(sectionId),
  });

  return creatingCount > 0 || updatingCount > 0 || deletingCount > 0;
};
