import { useMutation } from "@tanstack/react-query";
import { boronClient } from "../../api";
import { paths } from "../../lib/api/v1";
import { useNavigate } from "react-router";
import { AdminSection } from "../../interfaces/AdminSection";
import { MultiSectionBatchType } from "../../interfaces/MultiSectionBatchesOperation";
import { createError } from "../../errors";
import { useFlashMessage } from "../useFlashMessage";
import { addDays } from "date-fns";

type Props = {
  multiSectionBatchType: MultiSectionBatchType;
};

type PathMap = {
  [key in MultiSectionBatchType]: `/api/v1/sections/multi_section_batches/${key}`;
};

type Params = {
  adminSections: AdminSection[];
  startDate?: Date;
  endDate?: Date;
};

export const useMultiSectionBatchesDownload = ({
  multiSectionBatchType,
}: Props) => {
  const path: PathMap[MultiSectionBatchType] = `/api/v1/sections/multi_section_batches/${multiSectionBatchType}`;

  type RequestBody =
    paths[typeof path]["post"]["requestBody"]["content"]["application/json"];
  type ResponseBody =
    paths[typeof path]["post"]["responses"]["200"]["content"]["application/json"];

  const navigate = useNavigate();
  const { showErrorMessage } = useFlashMessage();

  const { isPending, mutate } = useMutation<ResponseBody, unknown, Params>({
    mutationFn: async ({ adminSections, startDate, endDate }) => {
      const requestBody: RequestBody = constructRequestBody({
        adminSections,
        startDate,
        endDate,
      });

      const { response } = await boronClient.POST(path, {
        body: requestBody,
      });

      if (response.ok) {
        return response.json();
      }

      throw await createError(response);
    },
    onSuccess: (data) => {
      navigate(`/multi_section_batches/${data.multi_section_batch_history_id}`);
    },
    onError: () => {
      showErrorMessage("作成に失敗しました");
    },
  });

  return {
    isPending,
    mutate,
  };
};

const constructRequestBody = ({
  adminSections,
  startDate,
  endDate,
}: {
  adminSections: AdminSection[];
  startDate?: Date;
  endDate?: Date;
}) => {
  const selectedSectionPublicIds = adminSections
    .filter((section) => section.checked)
    .map((section) => section.id);

  if (!startDate) {
    return {
      section_public_ids: selectedSectionPublicIds,
    };
  }

  return {
    section_public_ids: selectedSectionPublicIds,
    datetime_from: startDate.toISOString(),
    datetime_to: addDays(new Date(endDate ?? ""), 1).toISOString(), // NOTE: API側でendDateを含まない範囲で集計されるため、ユーザーが選択した日付を含めるために1日加算
  };
};
