import * as React from "react";
import enhanceSectionsSettingsPage from "../../../hocs/enhanceSectionsSettingsPage";
import SectionInterface from "../../../interfaces/SectionInterface";
import AppStateInterface from "../../../interfaces/AppStateInterface";
import { connect } from "react-redux";
import { RouterProps } from "react-router";
import SectionsSettingsStudentsNew from "./SectionsSettingsStudentsNew/index";
import { OutletContextProps } from "../../../helpers/RouterHelper";

interface Props extends RouterProps, OutletContextProps {
  section: SectionInterface;
}

const SectionsSettingsStudentsNewPage = (props: Props): React.ReactElement => {
  React.useEffect(() => {
    props.setActiveMenu("students");
  }, []);
  return <SectionsSettingsStudentsNew section={props.section} />;
};

const pageInfo = {
  title: "生徒の登録",
};

const mapStateToProps = (state: AppStateInterface) => ({
  section: state.currentSection,
});

const EnhancedPage = enhanceSectionsSettingsPage(
  SectionsSettingsStudentsNewPage,
  pageInfo,
);

export default connect(mapStateToProps, null)(EnhancedPage as any);
