import { useBoronMutation } from "../../../../hooks/http/useBoronMutation";
import { boronClient } from "../../../../api";

export const buildKey = (sectionId: string) => ["updateTag", sectionId];

export const useUpdateTag = ({ sectionId }: { sectionId: string }) => {
  return useBoronMutation(
    async ({ tagId, name }: { tagId: string; name: string }) =>
      await boronClient.PATCH(
        "/api/v1/sections/{section_id}/tags/{tag_hashid}",
        {
          params: { path: { section_id: sectionId, tag_hashid: tagId } },
          body: {
            tag: {
              name,
            },
          },
        },
      ),
    {
      mutationKey: buildKey(sectionId),
    },
  );
};
